import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import { CookiesProvider } from "react-cookie";
import { AuthContext } from "./auth";
import LoginPage from "./pages/LoginPage";
import LogupPage from "./pages/LogupPage";
import PrivateRoute from "./components/PrivateRoute";
import HomePage from "./pages/HomePage";
import DetailPage from "./pages/DetailPage";
import MyJobPage from "./pages/MyJobPage";
import PostPage from "./pages/PostPage";
import SettingPage from "./pages/SettingPage";
import ConfirmPage from "./pages/ConfirmPage";
import ForgotPage from "./pages/ForgotPage";
import NewPasswordPage from "./pages/NewPasswordPage";
import TIA from "./api";

function App() {
  const [authTokens, setAuthTokens] = React.useState(
    sessionStorage.getItem("userId")
  );
  const setTokens = (data) => {
    setAuthTokens(data);
  };

  

  const [confirm, setConfirm] = React.useState(
    sessionStorage.getItem("confirm")
  );
  const setConfirms = (data) => {
    setConfirm(data);
  };

  const [role, setRole] = React.useState(sessionStorage.getItem("userRole"));
  const setRoles = (data) => {
    setRole(data);
  };

  const [complete, setComplete] = React.useState(
    sessionStorage.getItem("userComplete")
  );
  const setCompletes = (data) => {
    setComplete(data);
  };


  const [guard, setGuard] = React.useState(
    sessionStorage.getItem("token")
  );
  const setGuards = (data) => {
    setGuard(data);
    
  };

  React.useEffect(()=>{
    TIA.Config.authorization(guard)
  })

  return (
    <CookiesProvider>
      <AuthContext.Provider
        value={{
          authTokens,
          setAuthTokens: setTokens,
          confirm,
          setConfirm: setConfirms,
          role,
          setRole: setRoles,
          complete,
          setComplete: setCompletes,
          guard,
          setGuard:setGuards
        }}
      >
        <Router>
          <Route exact path="/" component={LoginPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/logup" component={LogupPage} />
          
          <Route path="/confirm" component={ConfirmPage} />
          <Route path="/forgot" component={ForgotPage} />
          <Route path="/reset_password/:email/:token" component={NewPasswordPage} />
          <PrivateRoute path="/home" component={HomePage} />
          <PrivateRoute path="/details/:id" component={DetailPage} />
          <PrivateRoute path="/my_jobs" component={MyJobPage} />
          <PrivateRoute path="/post_job" component={PostPage} />
          <PrivateRoute path="/settings" component={SettingPage} />
        </Router>
      </AuthContext.Provider>
    </CookiesProvider>
  );
}

export default App;
