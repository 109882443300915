import React from "react";
import { Link } from "react-router-dom";
import { theme } from "../../../constants";
import { useAuth } from "../../../auth";

const Cv = (props) => {
  const { data } = props;
  const { role } = useAuth();

  return (
    
    <div {...props}>
    
      <Link to={"/details/" + data.id} style={{ color: "#000" }}>
        <div className="d-flex mb-3  mt-2">
          <div className="col-lg-2">
            <span
              className="fa fa-user-circle-o"
              style={{ color: theme.color.default, fontSize: 70 }}
            ></span>
          </div>
          <div className="col-lg-8 ">
            <h5>
              {data.prenom} {data.nom}
            </h5>
            <p>
              {data.skills.slice(0, 2).map((el, key) => {
                return (
                  <button
                    type="button"
                    class="btn btn-outline-info btn-sm mr-1 mb-1"
                  >
                    {el.name}
                  </button>
                );
              })}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Cv;
