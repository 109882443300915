import React from "react";
import logo from "../../assets/tia.png";
import { Button } from "../../ui/barboza";
import TIA from "../../api";
import { useAuth } from "../../auth";
import { Redirect } from "react-router-dom";

const LoginPage = () => {
  const [loader, setLoader] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [server, setServer] = React.useState(false);
  const {
    setAuthTokens,
    setConfirm,
    setRole,
    setComplete,
    setGuard,
  } = useAuth();

  function login(event) {
    event.preventDefault();
    let formData = new FormData(event.target);
    setLoader(true);
    TIA.Auth.auth(formData)
      .then((res) => {
        setLoader(false);
        if (res.data.result) {
          sessionStorage.setItem("userId", res.data.user.id.toString());
          sessionStorage.setItem("userRole", res.data.user.roles[0]);
          sessionStorage.setItem("userPrenom", res.data.user.prenom);
          sessionStorage.setItem("userNom", res.data.user.nom);
          sessionStorage.setItem("token", res.data.token);
          TIA.Config.authorization(res.data.token);
          if (res.data.user.roles[0] == "ROLE_USER") {
            if (res.data.user.confirm_token == "1") {
              sessionStorage.setItem("confirm", true);
              setConfirm(true);
            } else {
              sessionStorage.setItem("confirm", false);
              setConfirm(false);
            }
          } else {
            setComplete(true);
            sessionStorage.setItem("userComplete", true);

            if (res.data.user.entreprise.approved) {
              sessionStorage.setItem("confirm", true);
              setConfirm(true);
            } else {
              sessionStorage.setItem("confirm", false);
              setConfirm(false);
            }
          }

          setRole(res.data.user.roles[0]);
          setAuthTokens(res.data.user.id);
          setGuard(res.data.token);
          setRedirect(true);
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        setLoader(false);
        setServer(true);
        console.log(error);
      });
  }
  if (redirect) {
    return <Redirect to="/home" />;
  }
  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <div className="row justify-content-center">
                <img src={logo} width="150" />
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <form onSubmit={login}>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Adresse e-mail</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        name="username"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword2">
                        Mot de passe
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword2"
                        name="password"
                      />
                    </div>
                    <Button
                      className="btn bg-default text-white col mb-3"
                      onLoad={loader}
                    >
                      Connexion
                    </Button>

                    <a href="/forgot" className="card-link color-default">
                      Mot de passe oublié?
                    </a>
                  </form>
                  {error ? (
                    <div
                      class="alert alert-warning alert-dismissible fade show mt-3"
                      role="alert"
                    >
                      <strong>Adresse e-amil ou mot de passe incorrecte</strong>
                      <button
                        type="button"
                        class="close"
                        onClick={() => setError(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}
                  {server ? (
                    <div
                      class="alert alert-danger alert-dismissible fade show mt-3"
                      role="alert"
                    >
                      <strong>
                        Problème de connéxion au serveur, veuillez verifier
                        votre connéxion internet ou réessayer plus tard
                      </strong>
                      <button
                        type="button"
                        class="close"
                        onClick={() => setServer(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}
                  <div className="dropdown-divider mt-3" />
                  <a href="/logup" className="card-link color-default">
                    Créer un compte
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
