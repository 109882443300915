import React from "react";

const Employer = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-5">
          <p>
            Votre compte a été crée avec succés, un email de validation vous
            sera envoyé
          </p>
        </div>
      </div>
    </div>
  );
};

export default Employer;
