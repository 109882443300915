import axios from "axios";

const Axios = axios.create({
  baseURL: "https://backend.group-tia.com",
});

const Auth = {
  auth(data) {
    return axios.post("https://backend.group-tia.com/login", data);
  },
};

const Config = {
  authorization(data) {
    Axios.defaults.headers.common["Authorization"] = data;
  },
};

const User = {
  get() {
    return Axios.get("/users");
  },
  post(data) {
    return Axios.post("/logup", data);
  },

  detail(id) {
    return Axios.get("/users/" + id);
  },
  contact(id, data) {
    return Axios.post("/agents/" + id + "/Contacter", data);
  },
  filter(idAgent, data) {
    return Axios.post("/agents/" + idAgent + "/search", data);
  },
  confirm(data) {
    return Axios.post("/confirmation", data);
  },
  sendMail() {
    return Axios.post("/email_send");
  },
  addAdresse(id, data) {
    return Axios.post("/auth/clients/" + id + "/addAdresse", data);
  },
  addCv(data) {
    return Axios.post("/cv", data);
  },
  updateCv(id, data) {
    return Axios.post("/cv/" + id + "?_method=PUT", data);
  },
  addSkill(id, data) {
    return Axios.post("/auth/clients/" + id + "/addSkill", data);
  },
  addSector(id, data) {
    return Axios.post("/auth/clients/" + id + "/addSecteur", data);
  },
  addExperiences(data) {
    return Axios.post("/work_ats", data);
  },
  deleteExperiences(data) {
    return Axios.post("/work_ats/" + data + "?_method=DELETE");
  },
  addLangue(data) {
    return Axios.post("/ln", data);
  },
  deleteLangue(data) {
    return Axios.post("/ln/" + data + "?_method=DELETE");
  },
  addOutil(data) {
    return Axios.post("/outils", data);
  },
  deleteOutil(data) {
    return Axios.post("/outils/" + data + "?_method=DELETE");
  },
  addEtude(id, data) {
    return Axios.post("/auth/clients/" + id + "/addEtude", data);
  },

  contact(data, guard) {
    return Axios.post("/contactes", data, {
      headers: {
        Authorization: guard,
      },
    });
  },
  forgot(data) {
    return Axios.post("/password/new", data);
  },
  confirmPassword(data) {
    return Axios.post("/password/confirm", data);
  },
  addEtude(data){
    return Axios.post("/client_etudes", data);
  },
  deleteEtude(data){
    return Axios.post("/client_etudes/" + data + "?_method=DELETE");
  }
};

const Secteur = {
  get() {
    return Axios.get("/secteurs");
  },
  addSecteur(id, data) {
    return Axios.post("/users/" + id + "?_method=PUT", data);
  },
  deleteSecteur(id, idSecteur) {
    return Axios.post(
      "/users/" + id + "/secteurs/" + idSecteur + "?_method=DELETE"
    );
  },
};

const Skill = {
  get() {
    return Axios.get("/skills");
  },
  addSkill(id, data) {
    return Axios.post("/users/" + id + "?_method=PUT", data);
  },
  deleteSkill(id, idSkill) {
    return Axios.post(
      "/users/" + id + "/skills/" + idSkill + "?_method=DELETE"
    );
  },
};

const Title = {
  get() {
    return Axios.get("/job_titles");
  },
  post(data, guard) {
    return Axios.post("/job_titles", data, {
      headers: {
        Authorization: guard,
      },
    });
  },
};

const Etude = {
  get(){
    return Axios.get("/etudes");
  }

};

const Job = {
  get() {
    return Axios.get("/jobs");
  },
  detail(id) {
    return Axios.get("/jobs/" + id);
  },
  postuler(data, config) {
    return Axios.post("/postules", data, {
      headers: {
        Authorization: config,
      },
    });
  },
  delete(id, data) {
    return Axios.post("/jobs/" + id + "?_method=PUT", data);
  },
  post(data, config) {
    return Axios.post("/jobs", data, {
      headers: {
        Authorization: config,
      },
    });
  },
};

const Entreprise = {
  get() {},
  detail(id) {
    return Axios.get("/entreprises" + id + "/details");
  },
  post(data) {
    return Axios.post("/auth/entreprise", data);
  },
};

const Adresse = {
  post(data) {
    return Axios.post("/adresses", data);
  },
  put(id, data) {
    return Axios.post("/adresses/" + id + "?_method=PUT", data);
  },
};

const Filter = {
  search(data) {
    return Axios.post("search?table=users&word=true", data);
  },
  filterEmployer(data) {
    return Axios.post("search?table=users", data);
  },
  filterJob(data) {
    return Axios.post("search?table=jobs", data);
  },
};

const TIA = {
  Config,
  Auth,
  User,
  Secteur,
  Skill,
  Title,
  Job,
  Entreprise,
  Adresse,
  Filter,
  Etude
};

export default TIA;
