import React from "react";
import { Link, useLocation } from "react-router-dom";
import TIA from "../../../api";
import Job from "../../../components/items/Job";
import { DataTable } from "../../../ui/barboza";

const Employer = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    getInfo(sessionStorage.getItem("userId"));
  }, [query.get("table")]);

  function getInfo(params) {
    TIA.User.detail(params)
      .then((res) => {
        if (res.data) {
          setData(
            res.data.entreprise.jobs.filter((el) => {
              return el.is_delete != true;
            })
          );
        }
      })
      .catch(() => {});
  }

  function deleteJob(params) {
    let formData = new FormData();
    formData.append("is_delete", true);
    TIA.Job.delete(params, formData)
      .then((res) => {
        if (res.data) {
          getInfo(sessionStorage.getItem("userId"));
        }
      })
      .catch(() => {});
  }
  return (
    <div className="container mt-3">
      <ul className="nav ml-4">
        <li className=" nav-item ">
          <Link
            className={
              query.get("table") == "open"
                ? " nav-link borderCard"
                : " nav-link "
            }
            style={{ color: "#000000a6" }}
            to={"/my_jobs?table=open"}
          >
            Offres ouvertes
          </Link>
        </li>

        {/* <li className=" nav-item ">
          <Link
            className={
              query.get("table") == "close"
                ? " nav-link borderCard"
                : " nav-link "
            }
            style={{ color: "#000000a6" }}
            to={"/my_jobs?table=close"}
          >
            Offres clôturées
          </Link>
        </li>

        <li className=" nav-item ">
          <Link
            className={
              query.get("table") == "wait"
                ? " nav-link borderCard"
                : " nav-link "
            }
            style={{ color: "#000000a6" }}
            to={"/my_jobs?table=wait"}
          >
            Offres en attentes de validation
          </Link>
        </li> */}
      </ul>

      <div className="row mt-4 p-4">
        <div className="col">
          {query.get("table") == "open" ? (
            <DataTable
              btnRight={
                <a href="/post_job" className="btn bg-default text-white">
                  Poster une offre
                </a>
              }
              searchBar
              className="table  table-hover"
              data={data}
              renderItem={(item, key) => (
                <Job
                  data={item}
                  key={key}
                  className="card mb-2"
                  onDelete={deleteJob}
                />
              )}
            />
          ) : query.get("table") == "close" ? (
            <DataTable
              searchBar
              className="table  table-hover"
              data={data}
              renderItem={(item, key) => (
                <Job
                  data={item}
                  key={key}
                  className="card mb-2"
                  onDelete={deleteJob}
                />
              )}
            />
          ) : query.get("table") == "wait" ? (
            <DataTable
              searchBar
              className="table  table-hover"
              data={data}
              renderItem={(item, key) => (
                <Job
                  data={item}
                  key={key}
                  className="card mb-2"
                  onDelete={deleteJob}
                />
              )}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Employer;
