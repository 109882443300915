import React from "react";
import TIA from "../../../api";
import { Link, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Button, DropSelector } from "../../../ui/barboza";
import { useAuth } from "../../../auth";

const Jober = () => {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [add, setAdd] = React.useState({
    adresse: false,
    cv: false,
    skill: false,
    secteur: false,
    work: false,
    outil: false,
    etude: false,
    langue: false,
  });

  const [sectors, setSectors] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [titles, setTitles] = React.useState([]);
  const [etudes, setEtudes] = React.useState([]);

  const [sector, setSector] = React.useState([]);
  const [skill, setSkill] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [etude, setEtude] = React.useState("");

  const [showTitle, setShowTitle] = React.useState(false);
  const [showSkill, setShowSkill] = React.useState(false);
  const [showSecteur, setShowSecteur] = React.useState(false);
  const [showWork, setShowWork] = React.useState(false);
  const [showLangue, setShowLangue] = React.useState(false);
  const [showOutil, setShowOutil] = React.useState(false);
  const [showFormation, setShowFormation] = React.useState(false);

  const { guard } = useAuth();

  const [step, setStep] = React.useState(1);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  React.useEffect(() => {
    getInfo();
    getData("clients", sessionStorage.getItem("userId"));
  }, [query.get("table")]);

  function getInfo() {
    TIA.User.detail(sessionStorage.getItem("userId"))
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  function getData(user, id) {
    TIA.Secteur.get(user, id)
      .then((res) => {
        if (res.data) {
          setSectors(res.data);
        }
      })
      .catch(() => {});

    TIA.Title.get(user, id)
      .then((res) => {
        setTitles(res.data);
      })
      .catch(() => {});

    TIA.Skill.get(user, id)
      .then((res) => {
        setSkills(res.data);
      })
      .catch(() => {});
    TIA.Etude.get()
      .then((res) => {
        setEtudes(res.data);
      })
      .catch(() => {});
  }

  function titleSelected(params) {
    console.log(params);
    if (params != null) {
      setTitle(params[0].id);
    }
  }

  function etudeSelected(params) {
    console.log(params);
    if (params != null) {
      setEtude(params[0].id);
    }
  }

  function sectorSelected(params) {
    if (params != null) {
      setSector(params);
    }
  }

  function addAdresse(params) {
    params.preventDefault();
    let formData = new FormData(params.target);
    setAdd({ adresse: true });

    if (data.adresse) {
      TIA.Adresse.put(data.adresse.id, formData)
        .then(() => {
          getInfo();
          setAdd({ adresse: false });
        })
        .catch(() => {
          setAdd({ adresse: false });
        });
    } else {
      TIA.Adresse.post(formData)
        .then(() => {
          getInfo();
          setAdd({ adresse: false });
        })
        .catch(() => {
          setAdd({ adresse: false });
        });
    }
  }

  function skillSelected(params) {
    if (params != null) {
      setSkill(params);
    }
  }

  function onAddSkill() {
    let formData = new FormData();
    setAdd({ skill: true });
    let dataSkill = [];
    skill.forEach((element) => {
      dataSkill.push(element.id);
    });
    if (dataSkill.length > 0) {
      formData.append("skills", JSON.stringify(dataSkill));
    } else {
      formData.append("skills", null);
    }
    TIA.Skill.addSkill(sessionStorage.getItem("userId"), formData)
      .then((res) => {
        getInfo();
        setAdd({ skill: false });
      })
      .catch(() => {
        setAdd({ skill: false });
      });
  }

  function onDeleteSkill(params) {
    TIA.Skill.deleteSkill(sessionStorage.getItem("userId"), params)
      .then((res) => {
        getInfo();
      })
      .catch(() => {});
  }

  function onAddSecteur() {
    let formData = new FormData();
    setAdd({ secteur: true });
    let dataSkill = [];
    sector.forEach((element) => {
      dataSkill.push(element.id);
    });
    if (dataSkill.length > 0) {
      formData.append("secteurs", JSON.stringify(dataSkill));
    } else {
      formData.append("secteurs", null);
    }
    TIA.Secteur.addSecteur(sessionStorage.getItem("userId"), formData)
      .then((res) => {
        getInfo();
        setAdd({ secteur: false });
      })
      .catch(() => {
        setAdd({ secteur: false });
      });
  }

  function onDeleteSecteur(params) {
    TIA.Secteur.deleteSecteur(sessionStorage.getItem("userId"), params)
      .then((res) => {
        getInfo();
      })
      .catch(() => {});
  }

  function onAddOutil(params) {
    params.preventDefault();
    setAdd({ outil: true });
    let formData = new FormData(params.target);
    TIA.User.addOutil(formData)
      .then(() => {
        getInfo();
        setAdd({ outil: false });
      })
      .catch(() => {
        setAdd({ outil: false });
      });
  }

  function onDeleteOutil(params) {
    TIA.User.deleteOutil(params)
      .then(() => {
        getInfo();
      })
      .catch(() => {});
  }

  function onAddLangue(params) {
    params.preventDefault();
    setAdd({ langue: true });
    let formData = new FormData(params.target);
    TIA.User.addLangue(formData)
      .then(() => {
        getInfo();
        setAdd({ langue: false });
      })
      .catch(() => {
        setAdd({ langue: false });
      });
  }

  function onDeleteLangue(params) {
    TIA.User.deleteLangue(params)
      .then(() => {
        getInfo();
      })
      .catch(() => {});
  }

  function onAddCv(event) {
    event.preventDefault();
    setAdd({ cv: true });
    let formData = new FormData(event.target);
    if (data.cv) {
      if (title) {
        formData.append("job_title", title);
      }

      if (formData.get("periode") == "1") {
        formData.append("nombreAnnee", formData.get("nbr"));
      } else {
        formData.append("nombreMois", formData.get("nbr"));
      }
      formData.delete("nbr");
      formData.delete("periode");
      TIA.User.updateCv(data.cv.id, formData)
        .then(() => {
          getInfo();
          setAdd({ cv: false });
        })
        .catch(() => {
          setAdd({ cv: false });
        });
    } else {
      formData.append("job_title", title);
      if (formData.get("periode") == "1") {
        formData.append("nombreAnnee", formData.get("nbr"));
      } else {
        formData.append("nombreMois", formData.get("nbr"));
      }
      formData.delete("nbr");
      formData.delete("periode");
      TIA.User.addCv(formData)
        .then(() => {
          getInfo();
          setAdd({ cv: false });
        })
        .catch(() => {
          setAdd({ cv: false });
        });
    }
  }

  function onAddExperience(event) {
    event.preventDefault();
    setAdd({ work: true });
    let formData = new FormData(event.target);
    TIA.User.addExperiences(formData)
      .then(() => {
        getInfo();
        setAdd({ work: false });
      })
      .catch(() => {
        setAdd({ work: false });
      });
  }

  function onDeleteExperience(params) {
    TIA.User.deleteExperiences(params)
      .then(() => {
        getInfo();
      })
      .catch(() => {});
  }

  function onAddEtude(event) {
    event.preventDefault();
    setAdd({ etude: true });
    let formData = new FormData(event.target);
    if (etude) {
      formData.append("etude", etude);
    }
    TIA.User.addEtude(formData)
      .then(() => {
        getInfo();
        setAdd({ etude: false });
      })
      .catch(() => {
        setAdd({ etude: false });
      });
  }

  function onDeleteEtude(params) {
    TIA.User.deleteEtude(params)
      .then(() => {
        getInfo();
      })
      .catch(() => {});
  }

  return (
    <div className="container mt-3">
      {loader ? (
        <div className="row justify-content-center">
          <div className="">
            <h5 className="text-center mt-2">
              <Skeleton count={1} circle={true} height={100} width={100} />
            </h5>

            <h5 className="text-center mt-2">
              <Skeleton count={1} height={20} width={100} />
            </h5>
            <div className="d-flex">
              <Skeleton count={1} height={20} width={150} />
            </div>
          </div>
        </div>
      ) : (
        <div className="row justify-content-center">
          <div className="">
            <h5 className="text-center mt-2">
              <img
                src={"http://servertia.group-tia.com/images/logo/" + data.image}
                width={100}
                alt={data.nom}
              />
            </h5>
            <h5 className="text-center mt-1">
              {data.prenom} {data.nom}
            </h5>
          </div>
        </div>
      )}

      <div className="container">
        <ul className="nav ml-4">
          <li className=" nav-item ">
            <Link
              className={
                query.get("table") == "info"
                  ? " nav-link borderCard"
                  : " nav-link "
              }
              style={{ color: "#000000a6" }}
              to={"/settings?table=info"}
            >
              Information
            </Link>
          </li>
          <li className=" nav-item ">
            <Link
              className={
                query.get("table") == "update"
                  ? " nav-link borderCard"
                  : " nav-link "
              }
              style={{ color: "#000000a6" }}
              to={"/settings?table=update"}
            >
              Mettre à jour mon cv
            </Link>
          </li>
        </ul>

        {query.get("table") == "info" ? (
          <div className="row mt-5">
            <div className="col mb-3">
              {loader ? (
                <Skeleton count={5} height={20} />
              ) : (
                <>
                  <h4>Informations personnelles</h4>
                  <ul>
                    <li>Nom : {data.nom}</li>
                    <li>Postnom : {data.postnom}</li>
                    <li>Prénom : {data.prenom}</li>
                    <li>Adresse e-mail : {data.email}</li>
                    <li>Numéro de téléphone : {data.phone}</li>
                    <li>Genre: {data.sex}</li>
                    <li>Nationalité: {data.nationalite}</li>
                  </ul>

                  <h4>Adresse physique</h4>
                  <ul>
                    {data.adresse ? (
                      <>
                        <li>Ville : {data.adresse.ville}</li>
                        <li>Commune : {data.adresse.commune}</li>
                        <li>Quartier : {data.adresse.quartie}</li>
                        <li>Avenue : {data.adresse.avenu}</li>
                      </>
                    ) : (
                      "Aucune adresse indiquée"
                    )}
                  </ul>

                  {data.cv ? (
                    <>
                      <h5>Emploi recherché</h5>
                      <ul>
                        <li>{data.cv.job_title.name}</li>
                      </ul>
                    </>
                  ) : null}
                  {data.skills.length > 0 ? (
                    <>
                      <h5>Profile et compétences clés</h5>
                      <ul>
                        {data.skills.map((el, index) => {
                          return <li key={index}>{el.name}</li>;
                        })}
                      </ul>
                    </>
                  ) : null}

                  {data.workAts.length > 0 ? (
                    <>
                      <h5>Parcours professionnel</h5>
                      <ul>
                        {data.workAts.map((el, index) => {
                          return (
                            <>
                              <li key={index}>
                                {el.entreprise}/{el.post}
                              </li>
                              <p>
                                {el.description != "null"
                                  ? el.description
                                  : null}
                              </p>
                            </>
                          );
                        })}
                      </ul>
                    </>
                  ) : null}

                  {data.clientEtudes.length > 0 ? (
                    <>
                      <h5>Formation académique ou professionnelle</h5>
                      <ul>
                        {data.clientEtudes.map((el, index) => {
                          return (
                            <>
                              <li key={index}>{el.name}</li>
                              <p>
                                {el.description != "null"
                                  ? el.description
                                  : null}
                              </p>
                            </>
                          );
                        })}
                      </ul>
                    </>
                  ) : null}

                  {data.outils.length > 0 ? (
                    <>
                      <h5>Connaissances informatiques</h5>
                      <ul>
                        {data.outils.map((el, index) => {
                          return <li key={index}>{el.name}</li>;
                        })}
                      </ul>
                    </>
                  ) : null}

                  {data.linguistiques.length > 0 ? (
                    <>
                      <h5>Connaissances linguistiques</h5>
                      <ul>
                        {data.linguistiques.map((el, index) => {
                          if (el.name != "null") {
                            return (
                              <>
                                <li key={index}>
                                  {el.name} ({el.niveau})
                                </li>
                              </>
                            );
                          }
                        })}
                      </ul>
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row mt-4">
              <div className="col-lg-4">
                <h6 className="card-subtitle text-muted mb-4 mt-2">
                  Adresse de résidence <span style={{ color: "red" }}>*</span>
                </h6>
              </div>
              <div className="col-lg-8">
                <div className="card-body">
                  <form onSubmit={addAdresse}>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="prenom">Avenue</label>

                          <input
                            type="text"
                            className="form-control"
                            id="prenom"
                            name="avenu"
                            defaultValue={
                              data.adresse ? data.adresse.avenu : null
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="nom">Quartier</label>
                          <input
                            type="text"
                            className="form-control"
                            id="nom"
                            name="quartie"
                            defaultValue={
                              data.adresse ? data.adresse.quartie : null
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="nom">Ville</label>

                          <input
                            type="text"
                            name="ville"
                            class="form-control "
                            required
                            defaultValue={
                              data.adresse ? data.adresse.ville : null
                            }
                          />
                        </div>
                      </div>

                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="nom">Commune</label>

                          <input
                            type="text"
                            name="commune"
                            class="form-control "
                            defaultValue={
                              data.adresse ? data.adresse.commune : null
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div />
                      <Button
                        className="btn bg-default btn-sm text-white  "
                        type="submit"
                        onLoad={add.adresse}
                      >
                        {data.adresse ? "Mettre à jour" : "Ajouter"}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-4">
                <h6 className="card-subtitle text-muted mb-4 mt-2">
                  Construire votre cv <span style={{ color: "red" }}>*</span>
                </h6>
                <p></p>
              </div>
              <div className="col-lg-8">
                <div className="card-body">
                  <form onSubmit={onAddCv}>
                    {showTitle ? (
                      <div className="row">
                        <div className="col">
                          <DropSelector
                            className="mb-3"
                            data={titles}
                            value="id"
                            label="name"
                            option={{ multiple: false }}
                            onSelected={titleSelected}
                          />
                        </div>
                        <div className="col-lg-3">
                          <div
                            class="btn-group mt-3"
                            role="group"
                            aria-label="Second group"
                          >
                            <button
                              type="button"
                              class="btn btn-secondary mr-1"
                              onClick={() => setShowTitle(false)}
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="row mb-4">
                      <div className="col ">
                        <div className="card">
                          <table class="table table-hover ">
                            <thead>
                              <tr>
                                <th scope="col" className="col">
                                  Emploi recherché
                                </th>

                                <th>
                                  {showTitle ? null : (
                                    <button
                                      className="btn btn-success"
                                      onClick={() => setShowTitle(true)}
                                    >
                                      ajouter
                                    </button>
                                  )}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.cv ? (
                                data.cv.job_title ? (
                                  <tr>
                                    <td>{data.cv.job_title.name}</td>
                                    <td></td>
                                  </tr>
                                ) : null
                              ) : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="bmd-label-floating"
                          >
                            Experience professionnelle
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleInputEmail1"
                            name="nbr"
                            defaultValue={
                              data.cv
                                ? data.cv.nombreAnnee
                                  ? data.cv.nombreAnnee
                                  : data.cv.nombreMois
                                  ? data.cv.nombreMois
                                  : null
                                : null
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="bmd-label-floating"
                          >
                            Période
                          </label>
                          <select
                            className="form-control"
                            name="periode"
                            required
                          >
                            <option value="o">Choisir...</option>
                            <option value="1">Anneés</option>
                            <option value="2">Mois</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="bmd-label-floating"
                          >
                            Importer votre carte ONEM si vous en avez
                          </label>
                          <input
                            className="form-control"
                            id="exampleInputEmail1"
                            type="file"
                            name="carteFile"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlTextarea1">
                            Motivation
                          </label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            name="motivation"
                            required
                            defaultValue={
                              data.cv
                                ? data.cv.motivation
                                  ? data.cv.motivation
                                  : null
                                : null
                            }
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div />
                      <Button
                        className="btn bg-default btn-sm text-white  "
                        type="submit"
                        onLoad={add.cv}
                      >
                        {data.cv ? "Mettre à jour" : "Ajouter"}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-4">
                <h6 className="card-subtitle text-muted mb-4 mt-2">
                  Vos expertises <span style={{ color: "red" }}>*</span>
                </h6>
              </div>
              <div className="col-lg-8">
                <div className="card-body">
                  {showSkill ? (
                    <div className="row">
                      <div className="col">
                        <DropSelector
                          className="mb-3"
                          data={skills}
                          value="id"
                          label="name"
                          option={{ multiple: true, limit: 3 }}
                          onSelected={skillSelected}
                        />
                      </div>
                      <div className="col-lg-3">
                        <div
                          class="btn-group mt-3"
                          role="group"
                          aria-label="Second group"
                        >
                          <button
                            type="button"
                            class="btn btn-secondary mr-1"
                            onClick={() => setShowSkill(false)}
                          >
                            Annuler
                          </button>
                          <Button
                            onLoad={add.skill}
                            type="button"
                            class="btn btn-secondary"
                            onClick={onAddSkill}
                          >
                            Ajouter
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="row mb-4">
                    <div className="col ">
                      <div className="card">
                        <table class="table table-hover ">
                          <thead>
                            <tr>
                              <th scope="col" className="col">
                                Compétences
                              </th>

                              <th>
                                {data.skills ? (
                                  data.skills.length < 10 ? (
                                    <button
                                      className="btn btn-success"
                                      onClick={() => setShowSkill(true)}
                                    >
                                      ajouter
                                    </button>
                                  ) : null
                                ) : (
                                  <button
                                    className="btn btn-success"
                                    onClick={() => setShowSkill(true)}
                                  >
                                    ajouter
                                  </button>
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.skills
                              ? data.skills.map((el, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{el.name}</td>
                                      <td>
                                        <span
                                          className="fa fa-trash cursor"
                                          style={{ color: "red" }}
                                          onClick={() => onDeleteSkill(el.id)}
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {showSecteur ? (
                    <div className="row">
                      <div className="col">
                        <DropSelector
                          className="mb-3"
                          data={sectors}
                          value="id"
                          label="nom_secteur"
                          option={{ multiple: true, limit: 3 }}
                          onSelected={sectorSelected}
                        />
                      </div>
                      <div className="col-lg-3">
                        <div
                          class="btn-group mt-3"
                          role="group"
                          aria-label="Second group"
                        >
                          <button
                            type="button"
                            class="btn btn-secondary mr-1"
                            onClick={() => setShowSecteur(false)}
                          >
                            Annuler
                          </button>
                          <Button
                            onLoad={add.secteur}
                            type="button"
                            class="btn btn-secondary"
                            onClick={onAddSecteur}
                          >
                            Ajouter
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="row mb-4">
                    <div className="col ">
                      <div className="card">
                        <table class="table table-hover ">
                          <thead>
                            <tr>
                              <th scope="col" className="col">
                                Secteurs
                              </th>

                              <th>
                                {data.secteurs ? (
                                  data.secteurs.length < 2 ? (
                                    <button
                                      className="btn btn-success"
                                      onClick={() => setShowSecteur(true)}
                                    >
                                      ajouter
                                    </button>
                                  ) : null
                                ) : (
                                  <button
                                    className="btn btn-success"
                                    onClick={() => setShowSecteur(true)}
                                  >
                                    ajouter
                                  </button>
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.secteurs
                              ? data.secteurs.map((el, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{el.nom_secteur}</td>
                                      <td>
                                        <span
                                          className="fa fa-trash cursor"
                                          style={{ color: "red" }}
                                          onClick={() => onDeleteSecteur(el.id)}
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-4">
                <h6 className="card-subtitle text-muted mb-4 mt-2">
                  Vos expériences professionnelles{" "}
                  <span style={{ color: "red" }}>*</span>
                </h6>
              </div>
              <div className="col-lg-8">
                <div className="card-body">
                  {showWork ? (
                    <div className="row ">
                      <div className="col mb-3">
                        <form onSubmit={onAddExperience}>
                          <div className="form-group">
                            <label htmlFor="prenom">Poste occupé</label>
                            <input
                              type="text"
                              className="form-control"
                              id="prenom"
                              name="post"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="prenom">Nom de l'entreprise</label>
                            <input
                              type="text"
                              className="form-control"
                              id="prenom"
                              name="entreprise"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="prenom">Description</label>
                            <input
                              type="text"
                              className="form-control"
                              id="prenom"
                              name="description"
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="prenom">Date début</label>
                            <input
                              type="date"
                              className="form-control"
                              id="prenom"
                              name="date_debut"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="prenom">Date fin (optionnel)</label>
                            <input
                              type="date"
                              className="form-control"
                              id="prenom"
                              name="date_fin"
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <Button
                              className="btn btn-secondary  "
                              onClick={() => setShowWork(false)}
                            >
                              Annuler
                            </Button>
                            <Button
                              className="btn bg-default btn-sm text-white  "
                              type="submit"
                              onLoad={add.work}
                            >
                              Ajouter
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  ) : null}

                  <div className="row mb-4">
                    <div className="col ">
                      <div className="card">
                        <table class="table table-hover ">
                          <thead>
                            <tr>
                              <th scope="col" className="col">
                                Expériences
                              </th>

                              <th>
                                <button
                                  className="btn btn-success"
                                  onClick={() => setShowWork(true)}
                                >
                                  ajouter
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.workAts
                              ? data.workAts.map((el, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>
                                        {el.post} à {el.entreprise}
                                      </td>
                                      <td>
                                        <span
                                          className="fa fa-trash cursor"
                                          style={{ color: "red" }}
                                          onClick={() =>
                                            onDeleteExperience(el.id)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-4">
                <h6 className="card-subtitle text-muted mb-4 mt-2">
                  Langue parlée <span style={{ color: "red" }}>*</span>
                </h6>
              </div>
              <div className="col-lg-8">
                <div className="card-body">
                  {showLangue ? (
                    <div className="row ">
                      <div className="col mb-3">
                        <form onSubmit={onAddLangue}>
                          <div className="form-group">
                            <label htmlFor="prenom">Langues</label>
                            <input
                              type="text"
                              className="form-control"
                              id="prenom"
                              name="name"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="exampleSelect1"
                              className="bmd-label-floating"
                            >
                              Votre niveau
                            </label>
                            <select
                              className="form-control"
                              id="exampleSelect1"
                              name="niveau"
                              required
                            >
                              <option value="Basique">Basique</option>
                              <option value="Moyen">Moyen</option>
                              <option value="Eleve">Élevé</option>
                            </select>
                          </div>
                          <div className="d-flex justify-content-between">
                            <Button
                              className="btn btn-secondary  "
                              onClick={() => setShowLangue(false)}
                            >
                              Annuler
                            </Button>
                            <Button
                              onLoad={add.langue}
                              className="btn bg-default btn-sm text-white  "
                              type="submit"
                            >
                              Ajouter
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  ) : null}

                  <div className="row mb-4">
                    <div className="col ">
                      <div className="card">
                        <table class="table table-hover ">
                          <thead>
                            <tr>
                              <th scope="col" className="col">
                                Langues
                              </th>

                              <th>
                                <button
                                  className="btn btn-success"
                                  onClick={() => setShowLangue(true)}
                                >
                                  ajouter
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.linguistiques
                              ? data.linguistiques.map((el, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>
                                        {el.name}({el.niveau})
                                      </td>
                                      <td>
                                        <span
                                          className="fa fa-trash cursor"
                                          style={{ color: "red" }}
                                          onClick={() => onDeleteLangue(el.id)}
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-4">
                <h6 className="card-subtitle text-muted mb-4 mt-2">
                  Outils maitrisés <span style={{ color: "red" }}>*</span>
                </h6>
              </div>
              <div className="col-lg-8">
                <div className="card-body">
                  {showOutil ? (
                    <div className="row ">
                      <div className="col mb-3">
                        <form onSubmit={onAddOutil}>
                          <div className="form-group">
                            <label htmlFor="prenom">Nom de l'outil</label>
                            <input
                              type="text"
                              className="form-control"
                              id="prenom"
                              name="name"
                              required
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <Button
                              className="btn btn-secondary  "
                              onClick={() => setShowOutil(false)}
                            >
                              Annuler
                            </Button>
                            <Button
                              onLoad={add.outil}
                              className="btn bg-default btn-sm text-white  "
                              type="submit"
                            >
                              Ajouter
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  ) : null}

                  <div className="row mb-4">
                    <div className="col ">
                      <div className="card">
                        <table class="table table-hover ">
                          <thead>
                            <tr>
                              <th scope="col" className="col">
                                Outils
                              </th>

                              <th>
                                <button
                                  className="btn btn-success"
                                  onClick={() => setShowOutil(true)}
                                >
                                  ajouter
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.outils
                              ? data.outils.map((el, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{el.name}</td>
                                      <td>
                                        <span
                                          className="fa fa-trash cursor"
                                          style={{ color: "red" }}
                                          onClick={() => onDeleteOutil(el.id)}
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-4">
                <h6 className="card-subtitle text-muted mb-4 mt-2">
                  Formations académiques ou professionnelles
                  <span style={{ color: "red" }}>*</span>
                </h6>
              </div>
              <div className="col-lg-8">
                <div className="card-body">
                  {showFormation ? (
                    <div className="row ">
                      <div className="col mb-3">
                        <form onSubmit={onAddEtude}>
                          <div className="form-group">
                            <DropSelector
                              className="mb-3"
                              data={etudes}
                              value="id"
                              title="Niveau etudes"
                              label="etudes"
                              option={{ multiple: false }}
                              onSelected={etudeSelected}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="prenom">Établissement</label>
                            <input
                              type="text"
                              className="form-control"
                              id="prenom"
                              name="etablissement"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="prenom">Description</label>
                            <input
                              type="text"
                              className="form-control"
                              id="prenom"
                              name="description"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="prenom">Date début</label>
                            <input
                              type="date"
                              className="form-control"
                              id="prenom"
                              name="date_debut"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="prenom">Date fin (optionnel)</label>
                            <input
                              type="date"
                              className="form-control"
                              id="prenom"
                              name="date_fin"
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <Button
                              className="btn btn-secondary  "
                              onClick={() => setShowFormation(false)}
                            >
                              Annuler
                            </Button>
                            <Button
                              onLoad={add.etude}
                              className="btn bg-default btn-sm text-white  "
                              type="submit"
                            >
                              Ajouter
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  ) : null}

                  <div className="row mb-4">
                    <div className="col ">
                      <div className="card">
                        <table class="table table-hover ">
                          <thead>
                            <tr>
                              <th scope="col" className="col">
                                Formations
                              </th>

                              <th>
                                <button
                                  className="btn btn-success"
                                  onClick={() => setShowFormation(true)}
                                >
                                  ajouter
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.clientEtudes
                              ? data.clientEtudes.map((el, key) => {
                                  return (
                                    <tr>
                                      <td>
                                        {el.etablissement} / {el.etude.etudes}
                                      </td>
                                      <td>
                                        <span
                                          className="fa fa-trash cursor"
                                          style={{ color: "red" }}
                                          onClick={() => onDeleteEtude(el.id)}
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Jober;
