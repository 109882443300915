import React from "react";

import { Button } from "../../../ui/barboza";
import TIA from "../../../api";
import { useAuth } from "../../../auth";
import { Redirect } from "react-router-dom";

const Jober = () => {
  const [loader, setLoader] = React.useState(false);
  const [send, setSend] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [server, setServer] = React.useState(false);
  const { setConfirm } = useAuth();

  function onConfirm(params) {
    params.preventDefault();
    setLoader(true);
    let formData = new FormData(params.target);
    TIA.User.confirm(formData)
      .then((res) => {
        if (res.data.result) {
          sessionStorage.setItem("confirm", true);
          setConfirm(true);
          setError(true);
          window.location.replace("http://job.group-tia.com/home");
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
        setServer(true);
      });
  }

  function onSendMail() {
    setSend(true);
    TIA.User.sendMail()
      .then(() => {
        setSend(false);
        setSuccess(true);
      })
      .catch(() => {
        setSend(false);
        setServer(true);
      });
  }
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="">
            <div className="card-body">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <form onSubmit={onConfirm}>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        Code de confirmation
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        name="confirm_token"
                      />
                    </div>

                    <Button
                      className="btn bg-default text-white col mb-3"
                      onLoad={loader}
                    >
                      Confirmer
                    </Button>
                  </form>
                  <Button
                    className="btn btn-light col mb-3"
                    onLoad={send}
                    onClick={onSendMail}
                  >
                    Renvoyer l'e-mail
                  </Button>

                  {success ? (
                    <div
                      class="alert alert-success alert-dismissible fade show mt-3"
                      role="alert"
                    >
                      <strong>E-mail envoyé</strong>
                      <button
                        type="button"
                        class="close"
                        onClick={() => setSuccess(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}
                  {error ? (
                    <div
                      class="alert alert-warning alert-dismissible fade show mt-3"
                      role="alert"
                    >
                      <strong>Confirmation réussi</strong>
                      <button
                        type="button"
                        class="close"
                        onClick={() => setError(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}
                  {server ? (
                    <div
                      class="alert alert-danger alert-dismissible fade show mt-3"
                      role="alert"
                    >
                      <strong>
                        Probleme de connexion au serveur, veuillez verifier
                        votre connexion internet ou reessayer plus tard
                      </strong>
                      <button
                        type="button"
                        class="close"
                        onClick={() => setServer(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jober;
