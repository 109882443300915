import React from "react";
import logo from "../../../assets/tia.png";
import { Button } from "../../../ui/barboza";
import TIA from "../../../api";
import { useAuth } from "../../../auth";
import { Redirect } from "react-router-dom";

const Jober = () => {
  const [loader, setLoader] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [server, setServer] = React.useState(false);
  const { setAuthTokens, setConfirm, setRole } = useAuth();
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
        <div className="card">
            <div className="card-body">
              <div className="row justify-content-center">
                <img src={logo} width="150" />
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <form >
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Adresse e-mail</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        name="email"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword2">
                        Mot de passe
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword2"
                        name="password"
                      />
                    </div>
                    <Button
                      className="btn bg-default text-white col mb-3"
                      onLoad={loader}
                    >
                      Connexion
                    </Button>

                  </form>
                  {error ? (
                    <div
                      class="alert alert-warning alert-dismissible fade show mt-3"
                      role="alert"
                    >
                      <strong>Adresse e-amil ou mot de passe incorrecte</strong>
                      <button
                        type="button"
                        class="close"
                        onClick={() => setError(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}
                  {server ? (
                    <div
                      class="alert alert-danger alert-dismissible fade show mt-3"
                      role="alert"
                    >
                      <strong>
                        Probleme de connexion au serveur, veuillez verifier
                        votre connexion internet ou reessayer plus tard
                      </strong>
                      <button
                        type="button"
                        class="close"
                        onClick={() => setServer(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}
                  <div className="dropdown-divider mt-3" />
                  <a href="/logup" className="card-link color-default">
                    Créer un compte
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jober;
