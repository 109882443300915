import React from "react";
import TIA from "../../api";
import Header from "../../components/Header";
import { Button, Dropdown, DropSelector } from "../../ui/barboza";
import { useAuth } from "../../auth";

const PostPage = () => {
  const [data, setData] = React.useState();
  const [titles, setTitles] = React.useState([]);
  const [sectors, setSectors] = React.useState([]);
  const [skills, setSkills] = React.useState([]);

  const [loader, setLoader] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [sector, setSector] = React.useState([]);
  const [skill, setSkill] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const { guard } = useAuth();

  React.useEffect(() => {
    getInfo("agents", sessionStorage.getItem("userId"));
  }, []);

  function addJob(params) {
    params.preventDefault();
    setLoader(true);
    let formData = new FormData(params.target);

    let dataSkill = [];
    skill.forEach((element) => {
      dataSkill.push(element.id);
    });

    let dataSector = [];
    sector.forEach((element) => {
      dataSector.push(element.id);
    });

    if (dataSkill.length > 0) {
      formData.append("skills", JSON.stringify(dataSkill));
    }

    if (dataSector.length > 0) {
      formData.append("secteurs", JSON.stringify(dataSector));
    }

    formData.append("job_title", title);
    formData.delete("data");

    TIA.Job.post(formData, guard)
      .then((res) => {
        if (res.data) {
          setLoader(false);
          setSuccess(true);
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  }

  function addTitle(params) {
    let formData = new FormData();
    formData.append("name", params)
    TIA.Title.post(formData, guard)
      .then((res) => {
        if (res.data.result) {
          getInfo()
        }
      })
      .catch(() => {});
  }

  function getInfo(user, id) {
    TIA.Secteur.get(user, id)
      .then((res) => {
        if (res.data) {
          setSectors(res.data);
        }
      })
      .catch(() => {});

    TIA.Title.get(user, id)
      .then((res) => {
        setTitles(res.data);
      })
      .catch(() => {});

    TIA.Skill.get(user, id)
      .then((res) => {
        setSkills(res.data);
      })
      .catch(() => {});
  }

  function titleSelected(params) {
    if (params != null) {
      setTitle(params[0].id);
    }
  }
  function sectorSelected(params) {
    if (params != null) {
      setSector(params);
    }
  }

  function skillSelected(params) {
    if (params != null) {
      setSkill(params);
    }
  }
  return (
    <div>
      <Header page="logup" help="post_job" />
      <div className="container mb-4">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <form onSubmit={addJob}>
              <div className="form-group">
                <label htmlFor="prenom">Identifiez l'offre</label>
                <input
                  type="text"
                  className="form-control"
                  id="prenom"
                  name="ref"
                  
                />
              </div>

              <DropSelector
                className="mb-3"
                data={titles}
                value="id"
                title="Emploi"
                label="name"
                option={{ multiple: false }}
                editable={true}
                onEdit={addTitle}
                onSelected={titleSelected}
              />

              <div className="form-group">
                <label htmlFor="prenom">Date de clôture de l'offre</label>
                <input
                  type="date"
                  className="form-control"
                  id="prenom"
                  name="active_until"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="prenom">Lieu d'afféctation</label>
                <input
                  type="text"
                  className="form-control"
                  id="prenom"
                  name="vile"
                  required
                />
              </div>
              <DropSelector
                className="mb-3"
                data={sectors}
                value="id"
                title="Secteur"
                label="nom_secteur"
                option={{ multiple: true, limit: 2 }}
                onSelected={sectorSelected}
              />

              <DropSelector
                className="mb-3"
                data={skills}
                value="id"
                title="Competences"
                label="name"
                option={{ multiple: true, limit: 10 }}
                onSelected={skillSelected}
              />

              <div className="form-group">
                <label htmlFor="prenom">Courte déscription de l'offre</label>
                <textarea
                  className="form-control"
                  id="exampleTextarea"
                  rows="5"
                  name="desciption"
                  required
                ></textarea>
              </div>

              <div className="d-flex justify-content-between mt-4 mb-4">
                <a
                  href="/my_jobs?table=open"
                  className="btn btn-danger text-white"
                >
                  Annuler
                </a>
                <Button className="btn bg-default text-white" onLoad={loader}>
                  Enregistrer
                </Button>
              </div>
            </form>

            {success ? (
              <div
                class="alert alert-success alert-dismissible fade show mt-3"
                role="alert"
              >
                <strong>
                  Offre postée avec succés, veuillez patienter s'il vous plait
                  la validation de l'offre
                </strong>
                <button
                  type="button"
                  class="close"
                  onClick={() => setSuccess(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostPage;
