import React from "react";
import cv from "../../assets/cv.svg";
import boss from "../../assets/boss.svg";
import Header from "../../components/Header";
import { Link, useLocation } from "react-router-dom";
import Employer from "./Employer";
import Jober from "./Jober";

const LogupPage = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  React.useEffect(() => {}, [query.get("table")]);

  return (
    <div>
      <Header page="logup" />
      <div className="container">
        {query.get("table") == "employer" ? (
          <Employer />
        ) : query.get("table") == "jober" ? (
          <Jober />
        ) : (
          <>
            <div className="row justify-content-center mt-5">
              <h5 class="card-title">Créer un compte en tant que: </h5>
            </div>
            <div className="row justify-content-center mt-5">
              <div className="col-lg-4 mb-3">
                <div className="card border-bottom-default">
                  <div className="card-body">
                    <div className="row justify-content-center mb-5">
                      <img src={boss} width={200} />
                    </div>
                    <h5 class="card-title">Employeur</h5>

                    <p class="card-text">
                     
                    </p>

                    <Link
                      to="/logup?table=employer"
                      class="btn bg-default text-white"
                    >
                      Commencer
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="card border-bottom-default">
                  <div className="card-body">
                    <div className="row justify-content-center mb-5">
                      <img src={cv} width={215} />
                    </div>
                    <h5 class="card-title">Demandeur d'emploi</h5>
                    <p class="card-text">
                     
                    </p>

                    <Link
                      to="/logup?table=jober"
                      class="btn bg-default text-white"
                    >
                      Commencer
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LogupPage;
