import React, { Component } from "react";
import PropTypes from "prop-types";

function isEmpty(data) {
  for (let item in data) {
    return false;
  }
  return true;
}

const styles = {
  backgroundColor: "#f6f6f6",
  border: 1,
  padding: 5,
  marginRight: 5,
  color: "#1a1a1a",
};

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      newData: [],
      selectedElement: "",
      selectedElements: [],
    };
  }

  delete = () => {
    const { onSelected, render } = this.props;
    onSelected(null);
    this.setState({
      selectedElement: "",
    });
  };

  deletes = (element) => {
    const { onSelected } = this.props;
    let v = this.state.selectedElements;
    let a = v.filter((t) => {
      return t !== element;
    });
    this.setState({
      selectedElements: a,
    });

    if (!isEmpty(a)) {
      onSelected(a);
    } else {
      onSelected(null);
    }
  };

  _selected = (element) => {
    const { onSelected, render } = this.props;
    if (!isEmpty(element)) {
      onSelected(element);
      this.setState({
        selectedElement: element[render],
        data: "",
      });
    }
  };

  _selecteds = (element) => {
    const { onSelected } = this.props;
    let a = this.state.selectedElements;
    a.push(element);
    if (!isEmpty(element)) {
      this.setState({
        selectedElements: a,
        data: "",
      });
      onSelected(this.state.selectedElements);
    }
  };

  _filter = (event) => {
    const target = event.target;
    const value = target.value;

    const name = target.name;
    this.setState({
      [name]: value,
      newData: this.element(this.props.item, value),
    });
  };

  element = (arr, requete) => {
    const { render } = this.props;
    return arr.filter(function (el) {
      return el[render].toLowerCase().indexOf(requete.toLowerCase()) !== -1;
    });
  };

  _noMultiple = () => {
    const { render } = this.props;
    const title = this.state.newData.map((element, index) => {
      return (
        <li
          className="list-group-item activeElement"
          key={index}
          onClick={() => this._selected(element)}
        >
          {element[render]}
        </li>
      );
    });

    return (
      <div className="form-group ">
        <p>{this.props.title}</p>
        {this.state.selectedElement.length > 0 ? (
          <p>
            <span style={styles}>
              {this.state.selectedElement}
              <span
                style={{
                  fontWeight: "bold",
                  color: "black",
                  marginLeft: 5,
                  marginRight: 5,
                  fontSize: 20,
                }}
                onClick={() => this.delete()}
              >
                {" "}
                x
              </span>
            </span>
          </p>
        ) : null}

        <input
          type="text"
          className="form-control"
          name="data"
          onChange={(event) => this._filter(event, "dataTitles", "titles", 1)}
        />

        {this.state.data.length > 0 ? (
          <div className=" card  child3">
            <div className=" scrollable">
              <ul className="list-group">{title}</ul>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  _multiple = () => {
    const { render } = this.props;
    const title = this.state.newData.map((element, index) => {
      return (
        <li
          className="list-group-item activeElement"
          key={index}
          onClick={() => this._selecteds(element)}
        >
          {element[render]}
        </li>
      );
    });

    return (
      <div className="form-group ">
        <p>{this.props.title}</p>
        {this.state.selectedElements.length > 0 ? (
          <p>
            {this.state.selectedElements.map((el, key) => {
              return (
                <span style={styles} key={key}>
                  {el[render]}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      marginLeft: 5,
                      marginRight: 5,
                      fontSize: 20,
                    }}
                    onClick={() => this.deletes(el)}
                  >
                    {" "}
                    x
                  </span>
                </span>
              );
            })}
          </p>
        ) : null}

        <input
          type="text"
          className="form-control"
          name="data"
          onChange={(event) => this._filter(event, "dataTitles", "titles", 1)}
        />

        {this.state.data.length > 0 ? (
          <div className=" card  child3">
            <div className=" scrollable">
              <ul className="list-group">{title}</ul>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    const { multiple } = this.props;
    if (!multiple) {
      return this._noMultiple();
    } else {
      return this._multiple();
    }
  }
}

Dropdown.propTypes = {
  title: PropTypes.string,
  item: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired,
  render: PropTypes.string.isRequired,
  multiple: (PropTypes.bool.default = false),
};

export default Dropdown;
