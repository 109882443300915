export const url = "http://servertia.group-tia.com";
export const theme = {
  color: {
    default: "#293D82",
  },
};

export function isEmpty(obj) {
  for(var prop in obj) {
    if(obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return true;
}
