import React from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import TIA from "../../../api";
import { useAuth } from "../../../auth";

import { theme } from "../../../constants";
import { Button } from "../../../ui/barboza";

const Jober = () => {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const { id } = useParams();
  const { guard } = useAuth();

  React.useEffect(() => {
    getInfo(id);
  }, [id]);

  function getInfo(id) {
    TIA.Job.detail(id)
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  function contactCv(params) {
    setLoading(true);
    let formData = new FormData();
    formData.append("job", params);
    TIA.Job.postuler(formData, guard)
      .then((res) => {
        if (res.data.result) {
          setSuccess(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  return (
    <div className="container mt-4">
      <div className="row justify-content-center">
        <div className="col-lg-8 mb-5">
          {success ? (
            <div
              class="alert alert-success alert-dismissible fade show mt-3"
              role="alert"
            >
              <strong>Operation effectuée avec succés</strong>
              <button
                type="button"
                class="close"
                onClick={() => setSuccess(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          ) : null}

          {loader ? (
            <div className="card">
              <div className="card-body">
                <div className="borderCard ">
                  <div className="d-flex mb-2 justify-content-between">
                    <div className="d-flex">
                      <Skeleton
                        count={1}
                        circle={true}
                        height={80}
                        width={80}
                      />
                      <h5 className="mt-4 ml-3">
                        <Skeleton count={1} width={200} />
                      </h5>
                    </div>
                  </div>
                </div>
                <p className="mt-3">
                  <Skeleton count={10} />
                </p>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-body">
                <div className="borderCard mb-3">
                  <div className="d-flex mb-2 justify-content-between">
                    <div className="d-flex">
                      {data.image ? (
                        <img
                          src={
                            "https://backend.group-tia.com/uploads/logos/" +
                            data.entreprise.image
                          }
                          width={100}
                          alt={data.entreprise.nom_entreprise}
                        />
                      ) : (
                        <span
                          className="fa fa-briefcase"
                          style={{ color: theme.color.default, fontSize: 70 }}
                        ></span>
                      )}
                      <h5 className="ml-2">{data.job_title.name}</h5>
                    </div>

                    <div className="">
                      <Button
                        className="btn bg-default text-white"
                        onLoad={loading}
                        onClick={() => contactCv(data.id)}
                      >
                        Postuler
                      </Button>
                    </div>
                  </div>
                </div>

                <p
                  className="mt-5"
                  dangerouslySetInnerHTML={{ __html: data.desciption }}
                ></p>
                <p>Date limite : {data.activeUntil}</p>
                <p>Ville : {data.vile}</p>

                {data.skills.length > 0 ? (
                  <>
                    <h5>Profile et Compétences clés</h5>
                    <ul>
                      {data.skills.map((el, index) => {
                        return <li key={index}>{el.name}</li>;
                      })}
                    </ul>
                  </>
                ) : null}

                {data.secteurs.length > 0 ? (
                  <>
                    <h5>Secteur</h5>
                    <ul>
                      {data.secteurs.map((el, index) => {
                        return <li key={index}>{el.nom_secteur}</li>;
                      })}
                    </ul>
                  </>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Jober;
