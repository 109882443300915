import React from "react";
import PropTypes from "prop-types";

Object.size = function (obj) {
  var size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
};

const DataTable = (props) => {
  const { data, renderItem, customHeader, btnRight, searchBar } = props;
  const [page, setPage] = React.useState(10);
  const [number, setNumber] = React.useState(1);
  const [newTab, setNewTab] = React.useState(data);
  const [tab, setTab] = React.useState([]);

  React.useEffect(() => {
    setTab(data);
  });

  function paginate(array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  function pages() {
    return (
      Math.trunc(newTab.length / page) + (newTab.length % page !== 0 ? 1 : 0)
    );
  }

  function next() {
    if (number < pages()) {
      setNumber(number + 1);
    }
  }

  function previous() {
    if (number > 1) {
      setNumber(number - 1);
    }
  }

  function isEmpty(data) {
    for (let item in data) {
      return data;
    }
    return tab;
  }

  function filter(event) {
    if (event.length < 0) {
      setNewTab(data);
    } else {
      setNewTab(element(data, event));
    }
  }

  function element(arr, req) {
    return arr.filter(function (el) {
      let keys = Object.keys(el);
      let len = keys.length;

      for (let i = 0; i < len; i++) {
        if (typeof el[keys[i]] === "string") {
          if (el[keys[i]].toLowerCase().indexOf(req.toLowerCase()) !== -1) {
            return true;
          }
        }
      }

      return false;
    });
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          {searchBar ? (
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                onChange={(e) => filter(e.target.value)}
              />
            </div>
          ) : null}
        </div>
        <div>{btnRight}</div>
      </div>
      <table {...props}>
        {customHeader ? (
          <thead>
            <tr>
              {customHeader.map((el, key) => {
                return <th scope="col">{el}</th>;
              })}
            </tr>
          </thead>
        ) : null}

        <tbody>
          {paginate(isEmpty(newTab), page, number).map((el, key) => {
            return <>{renderItem(el, key)}</>;
          })}
        </tbody>
      </table>
      <div className="d-flex  justify-content-between">
        <div class="form-group">
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            onChange={(e) => setPage(e.target.value)}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>

        <div className="">
          <nav aria-label="Page navigation">
            <ul class="pagination">
              <li class="page-item" onClick={previous}>
                <a class="page-link">Precedent</a>
              </li>

              <li class="page-item">
                <a class="page-link">{number}</a>
              </li>

              <li class="page-item" onClick={next}>
                <a class="page-link">Suivant</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

DataTable.propTypes = {
  customHeader: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.array,
  ]),
  renderItem: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  btnRight: PropTypes.node,
  searchBar: PropTypes.bool,
};

DataTable.defaultProps = {
  searchBar: false,
};

export default DataTable;
