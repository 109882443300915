import React from "react";
import PropTypes, { object } from "prop-types";
import "./style.css";

const DropSelector = (props) => {
  const { data, value, label, option, onSelected, title, editable, onEdit } = props;
  const [drop, setDrop] = React.useState(false);
  const [element, setElement] = React.useState([]);
  const [newData, setNewData] = React.useState([]);
  const [bor, setBor] = React.useState();

  const Tag = ({ data }) => {
    return (
      <div class="btn-group mr-1 mb-1">
        <button type="button" class="btn btn-light btn-sm">
          {data[label]}
        </button>
        <button
          type="button"
          class="btn btn-light btn-sm "
          onClick={() => deleteTag(data)}
        >
          <span className="fa fa-trash-o " style={{ color: "red" }} />
        </button>
      </div>
    );
  };

  function addTag(params) {
    if (option.multiple) {
      if (option.limit) {
        let a = element;

        if (a.length < option.limit) {
          setElement((el) => [
            ...el.filter((el) => {
              return el[value] !== params[value];
            }),
            params,
          ]);

          if (!a.includes(params)) {
            a.push(params);
            onSelected(a);
          }
        }
      } else {
        let a = element;
        setElement((el) => [
          ...el.filter((el) => {
            return el[value] !== params[value];
          }),
          params,
        ]);

        if (!a.includes(params)) {
          a.push(params);
          onSelected(a);
        }
      }
    } else {
      setElement([params]);
      onSelected([params]);
    }
  }

  function deleteTag(params) {
    let a = element;

    let v = a.filter((el) => {
      return el[value] !== params[value];
    });
    setElement(v);
    if (v.length > 0) {
      onSelected(v);
    } else if (v.length == 0) {
      onSelected(null);
    }
  }

  function filterElement(params) {
    if (params.target.value) {
      setBor(params.target.value)
      setNewData(tri(data, params.target.value));
      setDrop(true);
    } else {
      setDrop(false);
    }
  }

  function tri(arr, requete) {
    return arr.filter(function (el) {
      return el[label].toLowerCase().indexOf(requete.toLowerCase()) !== -1;
    });
  }

  return (
    <div {...props}>
      <h6>{title}</h6>
      <div className="card p-1">
        <div>
          {element.map((el, key) => {
            return <Tag data={el} />;
          })}
        </div>

        <div className="d-flex justify-content-between">
          <div className="input-group">
            <input
              type="text"
              className="form-control input "
              placeholder="Selectionner..."
              onChange={filterElement}
              autocomplete="off"
            />
          </div>

          <div>
            <button
              class="btn "
              type="button"
              id="button-addon2"
              onClick={() => setDrop(!drop)}
            >
              <span className="fa fa-sort" style={{ color: "#000" }}></span>
            </button>
          </div>
        </div>
      </div>
      {drop ? (
        <div className="card mt-1 child scrollable">
          <ul class="list-group list-group-flush">
            {editable ? (
              <li class="list-group-item cursor" style={{ color: "green" }} onClick={()=>onEdit(bor)}>
                {" "}
                <span className="fa fa-plus-circle" /> Ajouter un nouvel element
              </li>
            ) : null}

            {newData.length > 0
              ? newData.map((el, key) => {
                  return (
                    <li
                      key={key}
                      class="list-group-item cursor"
                      onClick={() => addTag(el)}
                    >
                      {el[label]}
                    </li>
                  );
                })
              : data.map((el, key) => {
                  return (
                    <li
                      key={key}
                      class="list-group-item cursor"
                      onClick={() => addTag(el)}
                    >
                      {el[label]}
                    </li>
                  );
                })}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
DropSelector.propTypes = {
  data: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.string,
  isMultiple: PropTypes.bool,
  option: PropTypes.object,
  onSelected: PropTypes.func.isRequired,
  title: PropTypes.string,
  editable: PropTypes.bool,
  onEdit: PropTypes.func
};
DropSelector.defaultProps = {
  data: [],
  label: "",
  value: false,
  option: { multiple: false, limit: 1 },
  title: "",
  editable: false,
};

export default DropSelector;
