import React from "react";
import logo from "../../assets/tia.png";
import PropTypes from "prop-types";
import { useAuth } from "../../auth";
import TIA from "../../api";
import { isEmpty } from "../../constants";

const LogupHeader = ({ help }) => {
  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
      <div className="container">
        <a class="navbar-brand" href="/home">
          <img src={logo} width="100" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <a class="nav-link" href={"/help?for=" + help}>
                Besoin d'aide?
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const HomeHeader = ({ state }) => {
  const { role, setAuthTokens } = useAuth();

  function logout() {
    sessionStorage.clear();
    setAuthTokens(sessionStorage.getItem("userId"));
  }

  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
      <div className="container">
        <a class="navbar-brand" href="/home">
          <img src={logo} width="100" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            {role == "ROLE_AGENT" ? (
              <li class="nav-item active">
                <a class="nav-link" href="/my_jobs?table=open">
                  Mes offres
                </a>
              </li>
            ) : null}

            <li class="nav-item dropdown active">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {sessionStorage.getItem("userPrenom")}{" "}
                {sessionStorage.getItem("userNom")}{" "}
                {role !== "ROLE_AGENT" ? (
                  <span class="badge badge-danger">
                    Profil incomplet, veuillez le completé
                  </span>
                ) : null}
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  class="dropdown-item d-flex justify-content-between align-items-center"
                  href="/settings?table=info"
                >
                  Mon profil
                  {role !== "ROLE_AGENT" && state !== 100 ? (
                    <span class="badge badge-danger badge-pill ml-5">
                      {state}%
                    </span>
                  ) : null}
                </a>

                <div class="dropdown-divider"></div>

                <button
                  type="button"
                  class="dropdown-item btn btn-outline-dark btn-sm"
                  onClick={logout}
                >
                  Déconnexion
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const DetailHeader = ({ state }) => {
  const { role, setAuthTokens } = useAuth();

  function logout() {
    sessionStorage.clear();
    setAuthTokens(sessionStorage.getItem("userId"));
  }
  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-white borderCard">
      <div className="container">
        <a class="navbar-brand" href="/home">
          <img src={logo} width="100" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            {role == "ROLE_AGENT" ? (
              <li class="nav-item active">
                <a class="nav-link" href="/my_jobs?table=open">
                  Mes offres
                </a>
              </li>
            ) : null}

            <li class="nav-item dropdown active">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {sessionStorage.getItem("userPrenom")}{" "}
                {sessionStorage.getItem("userNom")}{" "}
                {role !== "ROLE_AGENT" ? (
                  state != 100 ? (
                    <span class="badge badge-danger">
                      Profil incomplet, veuillez le completé
                    </span>
                  ) : null
                ) : null}
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  class="dropdown-item d-flex justify-content-between align-items-center"
                  href="/settings?table=info"
                >
                  Mon profil
                  {role !== "ROLE_AGENT" && state !== 100 ? (
                    <span class="badge badge-danger badge-pill ml-5">
                      {state}% 
                    </span>
                  ) : null}
                </a>

                <div class="dropdown-divider"></div>

                <button
                  type="button"
                  class="dropdown-item btn btn-outline-dark btn-sm"
                  onClick={logout}
                >
                  Déconnexion
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const CompleteHeader = () => {
  const { role, setAuthTokens } = useAuth();

  function logout() {
    sessionStorage.clear();
    setAuthTokens(sessionStorage.getItem("userId"));
  }
  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-white ">
      <div className="container">
        <a class="navbar-brand" href="/home">
          <img src={logo} width="100" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            {role == "ROLE_AGENT" ? (
              <li class="nav-item active">
                <a class="nav-link" href="/my_jobs?table=open">
                  Mes offres
                </a>
              </li>
            ) : null}

            <li class="nav-item dropdown active">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {sessionStorage.getItem("userPrenom")}{" "}
                {sessionStorage.getItem("userNom")}
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="/settings?table=info">
                  Réglages
                </a>

                <div class="dropdown-divider"></div>

                <button
                  type="button"
                  class="dropdown-item btn btn-outline-dark btn-sm"
                  onClick={logout}
                >
                  Déconnexion
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const Header = ({ page, help }) => {
  const { role, authTokens } = useAuth();
  const [data, setData] = React.useState([]);
  const [step, setStep] = React.useState(20);

  React.useEffect(() => {
    onGetProfile(authTokens);
  }, []);
  function onGetProfile(id) {
    TIA.User.detail(id)
      .then((res) => {
        if (res.data) {
          if (res.data.linguistiques.length > 1) {
            setStep((v) => v + 10);
          }
          if (res.data.skills.length > 3) {
            setStep((v) => v + 15);
          }

          if (res.data.secteurs.length > 0) {
            setStep((v) => v + 15);
          }
          if (res.data.cv !== null) {
            setStep((v) => v + 10);
          }
          if (res.data.outils.length > 2) {
            setStep((v) => v + 10);
          }
          if (res.data.clientEtudes.length > 1) {
            setStep((v) => v + 10);
          }
          if (!isEmpty(res.data.adresse)) {
            setStep((v) => v + 10);
          }
        }
      })
      .catch(() => {});
  }

  return page == "logup" ? (
    <LogupHeader help={help} />
  ) : page == "home" ? (
    <HomeHeader role={role} state={step} />
  ) : page == "detail" ? (
    <DetailHeader role={role} state={step} />
  ) : page == "complete" ? (
    <CompleteHeader />
  ) : null;
};

Header.defaultProps = {
  page: "home",
  help: "",
};
export default Header;
