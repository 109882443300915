import React from "react";
import TIA from "../../../api";
import void_element from "../../../assets/void.svg";
import Job from "../../../components/items/Job";
import CvSkeleton from "../../../components/skeletons/CvSkeleton";
import { Dropdown, Button, DataTable, DropSelector } from "../../../ui/barboza";

const Jober = () => {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);

  const [sectors, setSectors] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [titles, setTitles] = React.useState([]);

  const [sector, setSector] = React.useState([]);
  const [skill, setSkill] = React.useState([]);
  const [title, setTitle] = React.useState([]);

  const [location, setLocation] = React.useState("");

  React.useEffect(() => {
    getInfo("clients", sessionStorage.getItem("userId"));
    getData("clients", sessionStorage.getItem("userId"));
  }, []);

  function getInfo(user, id) {
    TIA.Secteur.get().then((res) => {
      if (res.data) {
        setSectors(
          res.data.sort((a, b) =>
            a.nom_secteur > b.nom_secteur
              ? 1
              : b.nom_secteur > a.nom_secteur
              ? -1
              : 0
          )
        );
      }
    });

    TIA.Title.get().then((res) => {
      setTitles(
        res.data.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        )
      );
    });

    TIA.Skill.get().then((res) => {
      setSkills(
        res.data.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        )
      );
    });
  }

  function getData() {
    TIA.Job.get()
      .then((res) => {
        if (res.data) {
          setData(res.data);
        } else {
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  function filterElement(event) {
    event.preventDefault();
    setIsLoading(true);
    setLoader(true);
    let formData = new FormData();

    formData.append("location", location);

    let dataSkill = [];
    skill.forEach((element) => {
      dataSkill.push(element.id);
    });

    let dataSector = [];
    sector.forEach((element) => {
      dataSector.push(element.id);
    });

    let dataTitle = [];
    title.forEach((element) => {
      dataTitle.push(element.id);
    });

    if (dataSkill.length > 0) {
      formData.append("skills", JSON.stringify(dataSkill));
    } else {
      formData.append("skills", null);
    }

    if (dataSector.length > 0) {
      formData.append("secteur", JSON.stringify(dataSector));
    } else {
      formData.append("secteur", null);
    }

    if (dataTitle.length > 0) {
      formData.append("idJobTitle", JSON.stringify(dataTitle));
    } else {
      formData.append("idJobTitle", null);
    }

    TIA.Filter.filterJob(formData)
      .then((res) => {
        if (res.data) {
          setData(
            res.data.sort((a, b) =>
              a.prenom > b.prenom ? 1 : b.prenom > a.prenom ? -1 : 0
            )
          );
        }
        setIsLoading(false);
        setLoader(false);
      })
      .catch(() => {
        setIsLoading(false);
        setLoader(false);
      });
  }

  function titleSelected(params) {
    if (params != null) {
      setTitle(params);
    }
  }

  function sectorSelected(params) {
    if (params != null) {
      setSector(params);
    }
  }

  function skillSelected(params) {
    if (params != null) {
      setSkill(params);
    }
  }

  return (
    <div>
      <div className="borderCard" />

      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-3">
            <div className="card">
              <div className="card-body">
                <form onSubmit={filterElement}>
                  <DropSelector
                    className="mb-3"
                    data={titles}
                    value="id"
                    title="Emploi"
                    label="name"
                    option={{ multiple: true, limit: 3 }}
                    onSelected={titleSelected}
                  />

                  <DropSelector
                    className="mb-3"
                    data={sectors}
                    value="id"
                    title="Secteur"
                    label="nom_secteur"
                    option={{ multiple: true, limit: 3 }}
                    onSelected={sectorSelected}
                  />

                  <DropSelector
                    className="mb-3"
                    data={skills}
                    value="id"
                    title="Competences"
                    label="name"
                    option={{ multiple: true, limit: 3 }}
                    onSelected={skillSelected}
                  />

                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Ville {location}</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                  <Button
                    onLoad={isLoading}
                    className="btn bg-default text-white col"
                    type="submit"
                  >
                    Appliquer
                  </Button>
                </form>
              </div>
            </div>
          </div>
          <div className="col">
            {loader ? (
              <>
                <CvSkeleton className="card" />
                <CvSkeleton className="card mt-2" />
                <CvSkeleton className="card mt-2" />
              </>
            ) : data.length > 0 ? (
              <DataTable
                className="table  table-hover"
                data={data}
                renderItem={(item, key) => (
                  <Job data={item} key={key} className="card mb-2" />
                )}
              />
            ) : (
              <div className="row justify-content-center">
                <div className="col-lg-4">
                  <div className="card-body">
                    <img src={void_element} width="200" />
                    <p className="text-center mt-4">Pas d'offre disponible pour le moment</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jober;
