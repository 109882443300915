import React from "react";
import Header from "../../components/Header";
import { useAuth } from "../../auth";
import Employer from "./Employer";
import Jober from "./Jober";

const HomePage = () => {
  const { role } = useAuth();
  return (
    <div>
    
      <Header page="home" />
      {role == "ROLE_AGENT" ? <Employer /> : <Jober />}
    </div>
  );
};

export default HomePage;
