import React from "react";
import TIA from "../../../api";
import { Button, DropSelector, Modal } from "../../../ui/barboza";
import { useAuth } from "../../../auth";

const Jober = () => {
  const [loader, setLoader] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [same, setSame] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [dates, setDates] = React.useState(false);
  const [server, setServer] = React.useState(false);
  const [who, setWho] = React.useState();
  const {
    setAuthTokens,
    setConfirm,
    setRole,
    setComplete,
    setGuard,
  } = useAuth();

  function addUser(params) {
    params.preventDefault();
    setLoader(true);
    let formData = new FormData(params.target);
    if (who == "1") {
      formData.append("nationalite", "Congo(RDC)");
    }
    formData.append("roles", JSON.stringify(["ROLE_USER"]));
    if (formData.get("password") == formData.get("confirmPassword")) {
      formData.delete("confirmPassword");
      let toDay = new Date();
      let given = new Date(formData.get("naissance"));
      let year = toDay.getFullYear() - given.getFullYear();

      if (year > 18) {
        TIA.User.post(formData)
          .then((res) => {
            if (res.data.result) {
              setRedirect(true);
              sessionStorage.setItem("userId", res.data.user.id.toString());
              sessionStorage.setItem("userRole", res.data.user.roles[0]);
              sessionStorage.setItem("userPrenom", res.data.user.prenom);
              sessionStorage.setItem("userNom", res.data.user.nom);
              sessionStorage.setItem("token", res.data.token);
              sessionStorage.setItem("confirm", false);
              TIA.Config.authorization(res.data.token);
              sessionStorage.setItem("userComplete", false);
              setConfirm(false);
              setGuard(res.data.token);
              setRole(res.data.user.roles[0]);
              setAuthTokens(res.data.user.id.toString());
              setTimeout(function () {
                window.location.replace("http://job.group-tia.com/confirm");
              }, 4000);
              setComplete(false);
            } else {
              setError(true);
            }
            setLoader(false);
          })
          .catch(() => {
            setServer(true);
            setLoader(false);
          });
      } else {
        setDates(true);
        setLoader(false);
      }
    } else {
      setSame(true);
      setLoader(false);
    }
  }

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="">
            <div className="card-body">
              <h6 className="card-subtitle text-muted mb-4 mt-2">
                Informations personnelle
              </h6>
              <form onSubmit={addUser}>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="prenom">Prénom</label>
                      <input
                        type="text"
                        className="form-control"
                        id="prenom"
                        name="prenom"
                        required
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="nom">Nom</label>
                      <input
                        type="text"
                        className="form-control"
                        id="nom"
                        name="nom"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="prenom">Adresse e-mail</label>
                      <input
                        type="text"
                        className="form-control"
                        id="prenom"
                        name="email"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">Genre</label>
                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                        required
                        name="sex"
                      >
                        <option value="0">Choisir...</option>
                        <option value="HOMME">Homme</option>
                        <option value="FEMME">Femme</option>
                      </select>
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="nom">Numéro de téléphone</label>
                      <input
                        type="text"
                        className="form-control"
                        id="nom"
                        name="phone"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="prenom">Date de naissance </label>
                      <input
                        type="date"
                        className="form-control"
                        id="date"
                        name="naissance"
                        required
                      />
                      {dates ? (
                        <small style={{ color: "red" }}>
                          Vous devez avoir plus de 18 ans
                        </small>
                      ) : null}
                    </div>
                  </div>

                  <div className="col">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">
                        Nationnalité
                      </label>
                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                        onChange={(e) => setWho(e.target.value)}
                        required
                      >
                        <option value="0">Choisir...</option>
                        <option value="1">Congo(RDC)</option>
                        <option value="2">Etrangers</option>
                      </select>
                    </div>
                  </div>
                  {who == "2" ? (
                    <div className="col">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputEmail3"
                          className="bmd-label-floating"
                        >
                          Saisir votre nationalité
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail3"
                          name="nationalite"
                          required
                        />
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="nom">Définir un mot de passe</label>

                      <input
                        type={visible ? "text" : "password"}
                        name="password"
                        class="form-control "
                        aria-describedby="button-addon2"
                        required
                      />

                      {same ? (
                        <small style={{ color: "red" }}>
                          Les champs mot de passe doivent être identiques
                        </small>
                      ) : null}
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="nom">Confirmez le mot de passe</label>

                      <input
                        type={visible ? "text" : "password"}
                        name="confirmPassword"
                        class="form-control "
                        aria-describedby="button-addon2"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-4 mb-4">
                  <a href="/logup" className="btn btn-danger text-white">
                    Annuler
                  </a>
                  <Button className="btn bg-default text-white" onLoad={loader}>
                    Enregistrer
                  </Button>
                </div>
              </form>
              {redirect ? (
                <div
                  class="alert alert-success alert-dismissible fade show mt-3"
                  role="alert"
                >
                  <strong>
                    Votre inscription c'est effectuée avec succés, vous serai
                    rediriger vers la page de confirmation
                  </strong>
                  <button
                    type="button"
                    class="close"
                    onClick={() => setRedirect(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ) : null}

              {server ? (
                <div
                  class="alert alert-danger alert-dismissible fade show mt-3"
                  role="alert"
                >
                  <strong>
                    Problème de connéxion au serveur, veuillez verifier votre
                    connéxion internet ou réessayer plus tard
                  </strong>
                  <button
                    type="button"
                    class="close"
                    onClick={() => setServer(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Jober;
