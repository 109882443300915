import React from "react";
import { Button } from "../../ui/barboza";
import TIA from "../../api";
import { useAuth } from "../../auth";
import { Redirect, useParams } from "react-router-dom";
import Header from "../../components/Header";

const NewPasswordPage = () => {
  const [loader, setLoader] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [server, setServer] = React.useState(false);
  const { setAuthTokens, setConfirm, setRole } = useAuth();
  const { email, token } = useParams();
  const [same, setSame] = React.useState(false);

  function forgotPassword(params) {
    params.preventDefault();
    setLoader(true);
    let formData = new FormData(params.target);

    if (formData.get("password") == formData.get("confirm")) {
      TIA.User.confirmPassword(formData)
        .then((res) => {
          setLoader(false);
          if (res.data) {
            setError(true);
            setTimeout(function () {
              window.location.replace("http://job.group-tia.com/login");
            }, 3000);
          }
        })
        .catch((error) => {
          setLoader(false);
          setServer(true);
        });
    } else {
      setSame(true);
    }
  }
  return (
    <div>
      <Header page="logup" help="forgot" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="">
              <div className="card-body">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <form onSubmit={forgotPassword}>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Adresse e-mail
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          name="email"
                          defaultValue={email}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Nouveau mot de passe
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="exampleInputEmail1"
                          name="password"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Confirmer le mot de passe
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="exampleInputEmail1"
                          name="confirm"
                        />

                        <input
                          type="hidden"
                          className="form-control"
                          id="exampleInputEmail1"
                          name="token"
                          defaultValue={token}
                        />
                      </div>

                      {same ? (
                        <small style={{ color: "red" }}>
                          Les mots de passe doivent être identiques
                        </small>
                      ) : null}

                      <Button
                        className="btn bg-default text-white col mb-3"
                        onLoad={loader}
                      >
                        changer
                      </Button>
                    </form>
                    {error ? (
                      <div
                        class="alert alert-success alert-dismissible fade show mt-3"
                        role="alert"
                      >
                        <strong>
                          Opération effectué avec succés, vous serrez redirigé
                          vers la page de connexion dans quelque seconde si cela
                          n'est pas le cas{" "}
                          <a href="http://job.group-tia.com/login">
                            cliquez ici{" "}
                          </a>{" "}
                          afin de vous connecter
                        </strong>
                        <button
                          type="button"
                          class="close"
                          onClick={() => setError(false)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    ) : null}
                    {server ? (
                      <div
                        class="alert alert-danger alert-dismissible fade show mt-3"
                        role="alert"
                      >
                        <strong>
                          Problème de connexion au serveur, veuillez verifier
                          votre connexion internet ou reessayer plus tard
                        </strong>
                        <button
                          type="button"
                          class="close"
                          onClick={() => setServer(false)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPasswordPage;
