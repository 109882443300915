import React from "react";
import Header from "../../components/Header";
import { Link, useLocation } from "react-router-dom";
import Employer from "./Employer";
import { useAuth } from "../../auth";
import Jober from "./Jober";

const MyJobPage = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const { role } = useAuth();
  return (
    <div>
      <Header page="detail" />
      {role == "ROLE_AGENT" ? <Employer /> : <Jober />}
    </div>
  );
};

export default MyJobPage;
