import React from "react";
import TIA from "../../../api";
import { Link, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const Employer = () => {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  React.useEffect(() => {
    getInfo();
  }, [query.get("table")]);

  function getInfo() {
    TIA.User.detail(sessionStorage.getItem("userId"))
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  return (
    <div className="container mt-3">
      {loader ? (
        <div className="row justify-content-center">
          <div className="">
            <h5 className="text-center mt-2">
              <Skeleton count={1} circle={true} height={100} width={100} />
            </h5>

            <h5 className="text-center mt-2">
              <Skeleton count={1} height={20} width={100} />
            </h5>
            <div className="d-flex">
              <Skeleton count={1} height={20} width={150} />
            </div>
          </div>
        </div>
      ) : (
        <div className="row justify-content-center">
          <div className="">
            <h5 className="text-center mt-2">
              <img
                src={"https://backend.group-tia.com/uploads/entreprises/" + data.entreprise.image}
                width={100}
                alt={data.entreprise.nom_entreprise}
              />
            </h5>
            <h5 className="text-center mt-1">{data.entreprise.nom_entreprise}</h5>
            <div className="d-flex"></div>
          </div>
        </div>
      )}

      <div className="container">
        <ul className="nav ml-4">
          <li className=" nav-item ">
            <Link
              className={
                query.get("table") == "info"
                  ? " nav-link borderCard"
                  : " nav-link "
              }
              style={{ color: "#000000a6" }}
              to={"/settings?table=info"}
            >
              Information
            </Link>
          </li>
        </ul>
        <div className="row mt-5">
          <div className="col mb-3">
            {loader ? (
              <Skeleton count={5} height={20} />
            ) : (
              <>
                <h4>Information personnelle</h4>
                <ul>
                  <ol>Nom : {data.nom}</ol>
                  <ol>Postnom : {data.postnom}</ol>
                  <ol>Prénom : {data.prenom}</ol>
                  <ol>Adresse e-mail : {data.email}</ol>
                  <ol>Numéro de téléphone : {data.phone}</ol>
                </ul>
              </>
            )}
          </div>

          <div className="col">
            {loader ? (
              <Skeleton count={5} height={20} />
            ) : (
              <>
                <h4>Information sur la société</h4>

                <ul>
                  <ol>Nom de l'entreprise: {data.entreprise.nom_entreprise}</ol>

                  <ol>Code postal : {data.entreprise.code_postal}</ol>
                  <ol>Adresse physique : {data.entreprise.adresse}</ol>
                  <ol>Contact : {data.entreprise.contact}</ol>
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employer;
