import React from "react";
import { Button } from "../../ui/barboza";
import TIA from "../../api";
import { useAuth } from "../../auth";
import { Redirect } from "react-router-dom";
import Header from "../../components/Header";

const ForgotPage = () => {
  const [loader, setLoader] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [server, setServer] = React.useState(false);
  const { setAuthTokens, setConfirm, setRole } = useAuth();

  function forgotPassword(params) {
    params.preventDefault();
    setLoader(true);
    let formData = new FormData(params.target);
    formData.append("url", "http://job.group-tia.com/reset_password");

    TIA.User.forgot(formData)
      .then((res) => {
        if (res.data.result) {
          setError(true);
        } else {
          setRedirect(true);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setServer(true);
      });
  }
  return (
    <div>
      <Header page="logup" help="forgot" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="">
              <div className="card-body">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <form onSubmit={forgotPassword}>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Saisir votre adresse e-mail
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          name="email"
                        />
                      </div>

                      <Button
                        className="btn bg-default text-white col mb-3"
                        onLoad={loader}
                      >
                        Envoyer
                      </Button>
                    </form>
                    {error ? (
                      <div
                        class="alert alert-success alert-dismissible fade show mt-3"
                        role="alert"
                      >
                        <strong>
                          Un e-amil vous a été envoyer, si vous ne recevez pas
                          d'email dans les 10 minutes veuillez réessayer s'il
                          vous plait
                        </strong>
                        <button
                          type="button"
                          class="close"
                          onClick={() => setError(false)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    ) : null}
                    {server ? (
                      <div
                        class="alert alert-danger alert-dismissible fade show mt-3"
                        role="alert"
                      >
                        <strong>
                          Problème de connexion au serveur, veuillez verifier
                          votre connexion internet ou reessayer plus tard
                        </strong>
                        <button
                          type="button"
                          class="close"
                          onClick={() => setServer(false)}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPage;
