import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../auth";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { authTokens, confirm, complete, role } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        authTokens ? (
          confirm ? (
            <Component {...props} />
          ) : (
            <Redirect to="/confirm" />
          )
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;
