import React from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import TIA from "../../../api";
import {useAuth}  from "../../../auth"

import { theme } from "../../../constants";
import { Button } from "../../../ui/barboza";

const Employer = () => {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const { id } = useParams();
  const {guard} = useAuth()

  React.useEffect(() => {
    getInfo(id);
  }, [id]);

  function getInfo(id) {
    TIA.User.detail(id)
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  function contactCv(params) {
    setLoading(true);
    let formData = new FormData();
    formData.append("user", params);
    TIA.User.contact(formData, guard)
      .then((res) => {
        if (res.data.result) {
          setSuccess(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  return (
    <div className="container mt-4">
      <div className="row justify-content-center">
        <div className="col-lg-8 mb-5">
          {success ? (
            <div
              class="alert alert-success alert-dismissible fade show mt-3"
              role="alert"
            >
              <strong>
                Operation effectuée avec succés
              </strong>
              <button
                type="button"
                class="close"
                onClick={() => setSuccess(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          ) : null}

          {loader ? (
            <div className="card">
              <div className="card-body">
                <div className="borderCard ">
                  <div className="d-flex mb-2 justify-content-between">
                    <div className="d-flex">
                      <Skeleton
                        count={1}
                        circle={true}
                        height={80}
                        width={80}
                      />
                      <h5 className="mt-4 ml-3">
                        <Skeleton count={1} width={200} />
                      </h5>
                    </div>
                  </div>
                </div>
                <p className="mt-3">
                  <Skeleton count={10} />
                </p>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-body">
                <div className="borderCard mb-3">
                  <div className="d-flex mb-2 justify-content-between">
                    <div className="d-flex">
                      <span
                        className="fa fa-user-circle-o mr-3"
                        style={{ color: theme.color.default, fontSize: 50 }}
                      ></span>
                      <h5>
                        {" "}
                        {data.prenom} {data.nom}{" "}
                      </h5>
                    </div>

                    <div className="">
                      <Button
                        className="btn bg-default text-white"
                        onLoad={loading}
                        onClick={() => contactCv(data.id)}
                      >
                        Contacter
                      </Button>
                    </div>
                  </div>
                </div>
                {data.cv ? (
                  <>
                    <h5>Emploi recherché</h5>
                    <ul>
                      <li>{data.cv.name}</li>
                    </ul>
                  </>
                ) : null}
                {data.skills.length > 0 ? (
                  <>
                    <h5>Profile et Compétences clés</h5>
                    <ul>
                      {data.skills.map((el, index) => {
                        return <li key={index}>{el.name}</li>;
                      })}
                    </ul>
                  </>
                ) : null}

                {data.workAts.length > 0 ? (
                  <>
                    <h5>Parcours professionnel</h5>
                    <ul>
                      {data.workAts.map((el, index) => {
                        return (
                          <>
                            <li key={index}>
                              {el.entreprise}/{el.post} : du {el.dateDebut} au{" "}
                              {el.dateFin}
                            </li>
                            <p>
                              {el.description != "null" ? el.description : null}
                            </p>
                          </>
                        );
                      })}
                    </ul>
                  </>
                ) : null}

                {data.clientEtudes.length > 0 ? (
                  <>
                    <h5>Formation Académique ou Professionnelle</h5>
                    <ul>
                      {data.clientEtudes.map((el, index) => {
                        return (
                          <>
                            <li key={index}>{el.name}</li>
                            <p>
                              {el.description != "null" ? el.description : null}
                            </p>
                          </>
                        );
                      })}
                    </ul>
                  </>
                ) : null}

                {data.outils.length > 0 ? (
                  <>
                    <h5>Connaissances informatiques</h5>
                    <ul>
                      {data.outils.map((el, index) => {
                        return <li key={index}>{el.name}</li>;
                      })}
                    </ul>
                  </>
                ) : null}

                {data.linguistiques.length > 0 ? (
                  <>
                    <h5>Connaissances linguistiques</h5>
                    <ul>
                      {data.linguistiques.map((el, index) => {
                        if (el.name != "null") {
                          return (
                            <>
                              <li key={index}>
                                {el.name} ({el.niveau})
                              </li>
                            </>
                          );
                        }
                      })}
                    </ul>
                  </>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Employer;
