import React from "react";
import { Button } from "../../../ui/barboza";
import TIA from "../../../api";
import { useAuth } from "../../../auth";
import { Redirect } from "react-router-dom";

const Employer = () => {
  const [loader, setLoader] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [server, setServer] = React.useState(false);
  const { setAuthTokens, setConfirm, setRole } = useAuth();
  const [same, setSame] = React.useState(false);

  function addEntreprise(params) {
    params.preventDefault();
    let formData = new FormData(params.target);
    setLoader(true);
    
    formData.append("roles", JSON.stringify(["ROLE_AGENT"]))
    

    if (formData.get("password") == formData.get("confirmPassword")) {
      formData.delete("confirmPassword");
      TIA.User.post(formData)
        .then((res) => {
          if (res.data) {
            setRedirect(true);
            
          } else {
            setError(true);
          }
          setLoader(false);
        })
        .catch(() => {
          setServer(true);
          setLoader(false);
        });
    } else {
      setSame(true);
      setLoader(false);
    }
  }
  if (redirect) {
    return <Redirect to="confirm"/>
  }
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="card-body">
            <form onSubmit={addEntreprise}>
              <h5>Informations personnelles</h5>
              <div className="form-group mt-4">
                <label htmlFor="exampleInputEmail1">Prénom</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="prenom"
                  required
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="exampleInputEmail1">Nom</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="nom"
                  required
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="exampleInputEmail1">Numéro de téléphone</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="phone"
                  required
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="exampleInputEmail1">Adresse e-mail</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="email"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputPassword2">Mot de passe</label>

                <input
                  type="password"
                  name="password"
                  class="form-control "
                  aria-describedby="button-addon2"
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputPassword2">
                  Confirmer le mot de passe
                </label>

                <input
                  type="password"
                  name="confirmPassword"
                  class="form-control "
                  aria-describedby="button-addon2"
                />

                {same ? (
                  <small style={{ color: "red" }}>
                    Les champs mot de passe doivent être identique
                  </small>
                ) : null}
              </div>
              <div className="dropdown-divider mt-4 mb-4" />

              <h5>Informations sur la société</h5>

              <div className="form-group mt-4">
                <label htmlFor="exampleInputEmail1">
                  Importer le logo de la société si vous en avez
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="imageFile"
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="exampleInputEmail1">Nom de la société</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="nom_entreprise"
                  required
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="exampleInputEmail1">Numéro de téléphone</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="contact"
                  required
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="exampleInputEmail1">Adresse e-mail</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="emailEntreprise"
                  required
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="exampleInputEmail1">Adresse physique</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="adresseEntreprise"
                  required
                />
              </div>

              <Button
                className="btn bg-default text-white col mb-3"
                onLoad={loader}
              >
                S'enregistrer
              </Button>
              {redirect ? (
                <div
                  class="alert alert-success alert-dismissible fade show mt-3"
                  role="alert"
                >
                  <strong>Votre compte a été creer avec succés un e-mail vous sera envoyé, le temps de la validation par les administrateurs</strong>
                  <button
                    type="button"
                    class="close"
                    onClick={() => setRedirect(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ) : null}
              {error ? (
                <div
                  class="alert alert-warning alert-dismissible fade show mt-3"
                  role="alert"
                >
                  <strong>Complétez tous les champs correctement s'il vous plait</strong>
                  <button
                    type="button"
                    class="close"
                    onClick={() => setError(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ) : null}
              {server ? (
                <div
                  class="alert alert-danger alert-dismissible fade show mt-3"
                  role="alert"
                >
                  <strong>
                    Problème de connexion au serveur, veuillez verifié votre
                    connéxion internet ou réessayer plus tard
                  </strong>
                  <button
                    type="button"
                    class="close"
                    onClick={() => setServer(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employer;
