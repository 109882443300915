import React from 'react';
import Skeleton from 'react-loading-skeleton';

const CvSkeleton = (props) => {
  return (
    <div {...props}>
    <div className="d-flex mb-3 justify-content-between">
      <div className="col-lg-3">
        <Skeleton count={1} circle={true} height={100} width={100} />
      </div>
      <div className="col-lg-8 mt-4">
        <Skeleton count={2} />
      </div>
    </div>
  </div>
  );
}

export default CvSkeleton;
